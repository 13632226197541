// Kannada
export default{
    form: {
        startSurveyButton: "ಮೊದಲು ಆರಂಭಿಸು",
        nextButton: "ಮುಂದೆ",
        submitAnswerButton: "ಉತ್ತರ ಸಲ್ಲಿಸು",
        continueButton: "ಮುಂದುವರಿಸು",
        finishButton: "ಮುಗಿಸು",
        headerPercentageCompletion: 'ಪೂರ್ಣಗೊಳಿಸಲಾಗಿದೆ',
        headerQuestionsCompleted: 'ಪ್ರಶ್ನೆಗಳು',
        headerDefaultErrorMessage: 'ಅಯೊಡಾ. ಏನೋ ತಪ್ಪಾಗಿದೆ.',
        brandingPoweredBy: 'ಸಹಾಯದಿಂದ',
        brandingPromoWhySurvey: 'ನೀವು <b>{product}</b> ಬಳಸಿ ಕ್ವಿಸ್ಟನ್ ಮಾಡಬಹುದಾದರೆ ಏಕೆ ಸಮೀಕ್ಷೆ?',
        brandingPromoGetStarted: 'ಇಂದು ಪ್ರಾರಂಭಿಸು!',
        submissionPending: "ನಿಮ್ಮ ವಾಯ್ಸ್‌ಫಾರ್ಮ್ ಸಲ್ಲಿಸಲಾಗುತ್ತಿದೆ. ದಯವಿಟ್ಟು ನಿಮ್ಮ ಬ್ರೌಸರ್ ವಿಂಡೋವನ್ನು ಮುಚ್ಚಬೇಡಿ.",
        submissionCanCloseWindow: "ನಿಮ್ಮ ವಾಯ್ಸ್‌ಫಾರ್ಮ್ ಯಶಸ್ವಿಯಾಗಿ ಸಲ್ಲಿಸಲಾಗಿದೆ. ಈಗ ವಿಂಡೋವನ್ನು ಮುಚ್ಚಬಹುದು 👍",
        mediaAttachmentOverlayCloseButton: "ಮುಚ್ಚು",
        expiredFormHeader: "ಬಂದು ಹೋಗಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು!",
        expiredFormMessage: "ಈ ಸಮೀಕ್ಷೆಯ ಅವಧಿ ಮುಗಿದಿದೆ ಎಂದು ತೋರುತ್ತದೆ. ದಯವಿಟ್ಟು ವಾಯ್ಸ್‌ಫಾರ್ಮ್ ರಚನೆಗಾರರನ್ನು ಸಂಪರ್ಕಿಸಿ ಮತ್ತು ಅವರಿಗೆ ತಿಳಿಸಿ.",
        notPublishedFormHeader: "ವಾಯ್ಸ್‌ಫಾರ್ಮ್ ಪ್ರಕಟಿಸಲಾಗಿಲ್ಲ!",
        notPublishedFormMessage: "ಈ ಸಮೀಕ್ಷೆಯನ್ನು ಪ್ರಕಟಿಸಲಾಗಿಲ್ಲ ಎಂದು ತೋರುತ್ತದೆ. ದಯವಿಟ್ಟು ವಾಯ್ಸ್‌ಫಾರ್ಮ್ ರಚನೆಗಾರರನ್ನು ಸಂಪರ್ಕಿಸಿ ಮತ್ತು ಅವರಿಗೆ ತಿಳಿಸಿ.",
        notPublishedFormRetryButton: "ಮತ್ತೆ ಪ್ರಯತ್ನಿಸು",
        errorFormHeader: "ಅಯ್ಯೋ!",
        errorFormMessage: "ಏನೋ ತಪ್ಪಾಗಿದೆ ಎಂದು ತೋರುತ್ತದೆ. ದಯವಿಟ್ಟು ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸು.",
        errorFormRetryButton: "ಮತ್ತೆ ಪ್ರಯತ್ನಿಸು",
        emptyFormHeader: "ಬಂದು ಹೋಗಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು!",
        emptyFormMessage: "ಅಯ್ಯೋ! ಈ ಫಾರ್ಮ್‌ನಲ್ಲಿ ಪ್ರಸ್ತುತ ಯಾವುದೇ ಪ್ರಶ್ನೆಗಳಿಲ್ಲ ಅಥವಾ ಭರ್ತಿ ಮಾಡಲು ಕ್ಷೇತ್ರಗಳಿಲ್ಲ ಎಂದು ತೋರುತ್ತದೆ. ಈ ಅಸೌಕರ್ಯದಿಗಾಗಿ ನಾವು ಕ್ಷಮೆಯಾಚಿಸುತ್ತೇವೆ. ದಯವಿಟ್ಟು ಈ ಸಮಸ್ಯೆಯ ಬಗ್ಗೆ ಫಾರ್ಮ್ ರಚನೆಗಾರರನ್ನು ಸಂಪರ್ಕಿಸಿ. ನಿಮ್ಮ ಸಹಕಾರಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು!",

        restoreFormHeader: "ನೀವು ಪ್ರಗತಿಯಲ್ಲಿ ಇರುವ ಫಾರ್ಮ್ ಹೊಂದಿದ್ದೀರಿ!",
        restoreFormMessage: "ನೀವು ಮುಂದುವರೆಯಲು ಬಯಸುತ್ತೀರಾ?",
        restoreFormButton: "ಫಾರ್ಮ್ ಮುಂದುವರಿಸು",
        restoreFormButtonNew: "ಹೊಸದು ಪ್ರಾರಂಭಿಸು",

        audioPermissionHeader: "ನೀವು ಧ್ವನಿಯಿಂದ ಉತ್ತರಿಸಲು ಇಚ್ಛಿಸುತ್ತೀರಾ?",
        audioPermissionMessage: "ಮಾತನಾಡುವುದರಿಂದ ಉತ್ತರಿಸಲು ವೇಗವಾಗಿ ಮತ್ತು ಸುಲಭವಾಗುತ್ತದೆ.",
        audioPermissionAcceptButton: "ಹೌದು, ನಾನು ಬಯಸುತ್ತೇನೆ",
        audioPermissionDenyButton: "ಬೇಡ, ಧನ್ಯವಾದಗಳು",
        audioPermissionErrorHeader: "ಮೈಕ್ರೋಫೋನ್ ಲಭ್ಯವಿಲ್ಲ",
        audioPermissionErrorMessage: "ಮೈಕ್ರೋಫೋನ್‌ಗೆ ಪ್ರವೇಶವನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಲು ದಯವಿಟ್ಟು ನಿಮ್ಮ ಬ್ರೌಸರ್ ಪರವಾನಗಿಗಳನ್ನು ಪರಿಶೀಲಿಸಿ.",
        audioPermissionErrorButton: "ಸರಿ",
        audioRecorderFailedUpload: "ರೆಕಾರ್ಡಿಂಗ್ ಅನ್ನು ಅಪ್ಲೋಡ್ ಮಾಡಲು ವಿಫಲವಾಗಿದೆ",
        silenceDetectorError: "ನೀವು ಕೇಳಲಾಗುತ್ತಿಲ್ಲ. ದಯವಿಟ್ಟು ನಿಮ್ಮ ಮೈಕ್ರೋಫೋನ್ ಆಯ್ಕೆಗಳನ್ನು ಪರಿಶೀಲಿಸಿ. ಸೂಚನೆ: ಅದು ನಿಮ್ಮ ಬಾಹ್ಯ ಮೈಕ್ರೋಫೋನ್ ಆಗಿರಬಹುದು.",
        audioRecorderButtonRecord: "ರೆಕಾರ್ಡ್‌ ಮಾಡಿ",
        audioRecorderButtonRecordMore: "ಇನ್ನಷ್ಟು ರೆಕಾರ್ಡ್ ಮಾಡಿ",
        voiceResponseSelectionMessage: "ನೀವು ಹೇಗೆ ಉತ್ತರಿಸಲು ಬಯಸುತ್ತೀರೋ ಆಯ್ಕೆಮಾಡಿ...",
        voiceResponseSelectionOrDivider: "ಅಥವಾ",
        voiceResponseTextInputPlaceholder: "ಉತ್ತರವನ್ನು ಟೈಪ್ ಮಾಡಿ",
        voiceResponseTextInputCharacterCounterMinMessage: "{min} ಅಕ್ಷರಗಳನ್ನು ಕನಿಷ್ಠ ನಮೂದಿಸಿ",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} ಅಕ್ಷರಗಳು (ಕನಿಷ್ಠ {min} ಅಕ್ಷರಗಳು)",
        voiceResponseTextInputCharacterValidationMessage: "{count} ಅಕ್ಷರಗಳು (ಕನಿಷ್ಠ {min} ಅಕ್ಷರಗಳು)",

        // checkbox
        checkboxValidationTooFew: "{min} ಆಯ್ಕೆಗಳನ್ನು ಕನಿಷ್ಠ ಆಯ್ಕೆ ಮಾಡಬೇಕು",
        checkboxValidationTooMany: "ನೀವು {max} ಆಯ್ಕೆಗಳನ್ನು ಮೀರಿಸಬಾರದು",
        checkboxNoneOfTheAboveOption: "ಮೇಲಿನ ಯಾವುದೂ ಇಲ್ಲ",
        checkboxInvalidAnswer: "ಅಮಾನ್ಯ ಉತ್ತರ.",

        // datepicker
        datePickerPlaceholder: "ದಿನಾಂಕವನ್ನು ಆಯ್ಕೆಮಾಡಿ",

        // dropdown
        // email
        emailLabel: "ಇಮೇಲ್",
        emailInvalid:"ಅಮಾನ್ಯ ಇಮೇಲ್.",
        // file-upload
        fileUploadPluginNameCamera: "ಕ್ಯಾಮೆರಾ",
        fileUploadPluginNameCameraVideo: "ವೀಡಿಯೊ ರೆಕಾರ್ಡ್ ಮಾಡಿ",
        fileUploadPluginScreenCast: "ಸ್ಕ್ರೀನ್ ಕಾಸ್ಟ್",
        fileUploadVideoImportFilesDefault: 'ವೀಡಿಯೊವನ್ನು ರೆಕಾರ್ಡ್ ಮಾಡಿ ಅಥವಾ ಅಪ್ಲೋಡ್ ಮಾಡಿ:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'ವೀಡಿಯೊ ರೆಕಾರ್ಡ್ ಮಾಡುವ ಆಯ್ಕೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ:',
        fileUploadVideoImportFilesNoCamera: 'ವೀಡಿಯೊವನ್ನು ಸ್ಕ್ರೀನ್ ರೆಕಾರ್ಡ್ ಮಾಡಲು ಬಟನ್ ಒತ್ತಿ',
        fileUploadVideoImportFilesNoScreenCast: 'ವೀಡಿಯೊವನ್ನು ರೆಕಾರ್ಡ್ ಮಾಡಲು ಬಟನ್ ಒತ್ತಿ',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'ವೀಡಿಯೊವನ್ನು ರೆಕಾರ್ಡ್ ಮಾಡಲು ಯಾವುದೇ ಆಯ್ಕೆಗಳು ಲಭ್ಯವಿಲ್ಲ',

        // matrix
        matrixValidationMessage: "ಉತ್ತರವು ಮಾನ್ಯವಾಗಿದೆ.",
        matrixRow: "ಅಣು",
        matrixColumn: "ಕಾಲಮ್",
        matrixRowRequired: "{rowTitle} ಅಣು ಅಗತ್ಯವಿದೆ.",
        matrixRadioGroup: "ರೇಡಿಯೋ ಗುಂಪು",
        matrixCheckboxGroup: "ಚೆಕ್‌ಬಾಕ್ಸ್ ಗುಂಪು",
        matrixGroupRequired: "{rowTitle} ಅಣಿಗೆ. {type} {groupTitle} ಅಗತ್ಯವಿದೆ.",
        matrixColumnRequired: "{rowTitle} ಅಣಿಗೆ. ಕಾಲಮ್ {columnTitle} ಅಗತ್ಯವಿದೆ.",
        matrixColumnInvalid: "{rowTitle} ಅಣಿಗೆ. ಕಾಲಮ್ {columnTitle} ಅಮಾನ್ಯವಾಗಿದೆ.",
        matrixRequired: "ಅಗತ್ಯವಿದೆ.",
        matrixNumericMustBeNumber: "ಸಂಖ್ಯೆಯಾಗಿರಬೇಕು.",
        matrixNumericMustBeGreaterThenMin: "{min} ಕ್ಕಿಂತ ಹೆಚ್ಚಿನದಾಗಿರಬೇಕು.",
        matrixNumericMustBeLessThenMax: "{max} ಕ್ಕಿಂತ ಕಡಿಮೆ ಇರಬೇಕು.",
        matrixNumericMustBeInteger: "ಪೂರ್ಣಾಂಕವಾಗಿರಬೇಕು.",
        matrixNumericInvalidNumber: "ಅಮಾನ್ಯ ಸಂಖ್ಯೆ.",

        // name
        nameLabel: "ಹೆಸರು",
        nameInvalid: "ಮೊದಲ ಮತ್ತು ಕೊನೆಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ.",

        // nps
        npsNotLikely: "ತುಂಬಾ ಅಸಾಧ್ಯ",
        npsExtremelyLikely: "ತುಂಬಾ ಸಾಧ್ಯತೆ",

        // numeric input
        numericInputRequired: "ಅಗತ್ಯವಿದೆ.",
        numericInputMustBeNumber: "ಸಂಖ್ಯೆಯಾಗಿರಬೇಕು.",
        numericInputMustBeGreaterThenMin: "{min} ಕ್ಕಿಂತ ಹೆಚ್ಚಿನದಾಗಿರಬೇಕು.",
        numericInputMustBeLessThenMax: "{max} ಕ್ಕಿಂತ ಕಡಿಮೆ ಇರಬೇಕು.",
        numericInputMustBeInteger: "ಪೂರ್ಣಾಂಕವಾಗಿರಬೇಕು.",
        numericInputInvalidNumber: "ಅಮಾನ್ಯ ಸಂಖ್ಯೆ.",
        numericInputPlaceholder: "ಸಂಖ್ಯೆ ಟೈಪ್ ಮಾಡಿ",

        // phone
        phoneInvalid: "ದೂರವಾಣಿ ಸಂಖ್ಯೆ ಅಮಾನ್ಯವಾಗಿದೆ.",
        phoneCountrySelectorLabel: 'ದೇಶ ಕೋಡ್',
        phoneCountrySelectorError: 'ದೇಶವನ್ನು ಆಯ್ಕೆಮಾಡಿ',
        phoneNumberLabel: 'ದೂರವಾಣಿ ಸಂಖ್ಯೆ',
        phoneExample: 'ಉದಾಹರಣೆ:',

        // boolean
        booleanYesLabel: "ಹೌದು",
        booleanNoLabel: "ಇಲ್ಲ",

        //questionStep
        questionStepAudioQuestionLabel: "ಆಡಿಯೋ ಪ್ರಶ್ನೆ",

        // errors
        invalidPhoneNumber: "{phone} ಅಮಾನ್ಯ ದೂರವಾಣಿ ಸಂಖ್ಯೆ.",
        invalidEmail: "{email} ಅಮಾನ್ಯ ಇಮೇಲ್ ವಿಳಾಸ.",
        questionIsRequired: "ಪ್ರಶ್ನೆ ಅಗತ್ಯವಿದೆ.",
        answerIsNotValid: "ಉತ್ತರವು ಮಾನ್ಯವಲ್ಲ.",
        unfinishedProbingDialogError: "ದಯವಿಟ್ಟು ಸಂವಾದವನ್ನು ಪೂರ್ಣಗೊಳಿಸಿ.",
        cannotResumePartialResponse: "ಆದೇಶಿತ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ಪುನಃ ಆರಂಭಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ.",
        failedToSubmitForm: "ಫಾರ್ಮ್ ಸಲ್ಲಿಸಲು ವಿಫಲವಾಗಿದೆ. ನಿಮ್ಮ ಇಂಟರ್ನೆಟ್ ಸಂಪರ್ಕವನ್ನು ಪರಿಶೀಲಿಸಿ ಮತ್ತು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.",

        //language picker
        searchLanguage: "ಭಾಷೆಯನ್ನು ಹುಡುಕಿ",
    }
}
